<script>
import Multiselect from '@vueform/multiselect/src/Multiselect';
import ReservationsService from '../../services/api/ocpp/ReservationsService'
import VueJsonPretty from 'vue-json-pretty'
import 'vue-json-pretty/lib/styles.css';

export default {
    name: 'ReservationsPage',
    components: {
        Multiselect,
        VueJsonPretty
    },
    data: function () {
        return {
            reservationsService: new ReservationsService(this),
            isLoading: false,
            reservationDetails: null,
            logMessages: [],
            filteredLogMessages: [],
            reservation: null,
            driverVehicles: null,
            driver: null,
            sessionRollups: null,
            transactionIds: [],
            selectedComponents: [],
            components: [],
            fields: [
                {
                    key: "timestamp",
                    label: "Time",
                },
                {
                    key: "componentName",
                    label: "component",
                    formatter: this.renderColumn,
                },
                {
                    key: "title",
                    label: "title",
                },
                {
                    key: "success",
                    label: "success",
                    formatter: this.renderColumn,
                },
                {
                    key: "systemInitiated",
                    label: "system initiated?",
                    formatter: this.renderColumn,
                },
                {
                    key: "id",
                    label: "ID",
                },
                {
                    key: "action",
                    label: "action",
                }
                
            ],
            currentPage: 1,
            totalRows: 0,
            perPage: 50,
            filter: null
        }
    },
    methods: {
        getReservationById: async function () {
            const reservationDetails = await this.reservationsService.getReservationById(this.$route.params.id);
            this.logMessages = this.filteredLogMessages = await this.reservationsService.getReservationLogMessagesById(this.$route.params.id);
            this.filteredLogMessages = this.logMessages.sort((m1, m2) => {m1.timestamp < m2.timestamp
                return new Date(m1.timestamp) - new Date(m2.timestamp)
            })
            // reservationDetails should have driver, driverVehicles, reservation, sessionRollups
            if (!reservationDetails)
                return

            this.reservationDetails = reservationDetails

            if (reservationDetails.reservation) {
                this.reservation = reservationDetails.reservation
            }

            if (reservationDetails.driverVehicles) {
                this.driverVehicles = reservationDetails.driverVehicles.map(v => {
                    return {
                        make: v.make,
                        model: v.model,
                        year: v.year,
                        efficiency: v.combE.toFixed(2) + " kWh/mi",
                        range: v.range || v.rangeA + " miles"
                    }
                })
            }

            if (reservationDetails.driver) {
                this.driver = reservationDetails.driver
            }

            if (reservationDetails.sessionOverviews) {
                // Extract each overviews rollup array into sessionRollups array and add transactionId from session overview
                this.sessionRollups = []
                for (const sessionOverview of reservationDetails.sessionOverviews) {
                    const sessionRollups = sessionOverview.sessionRollup
                    for (const rollup of sessionRollups)
                        this.sessionRollups.push({
                    // TODO: Fix local timezone (reservation startTime, endTime and rollup date do not match)
                            "date": new Date(rollup.date).toLocaleString('en-US', {
                                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
                            }),
                            "transaction id": sessionOverview.transactionID,
                            "energy": rollup.energy + " kWh",
                            "average power": rollup.avgPower + " kW",
                            "peak power": rollup.peakPower + " kW",
                            "average current": rollup.avgCurrent + " A",
                            "peak current": rollup.peakCurrent + " A",
                            "average voltage": rollup.avgVoltage + " V",
                            "peak voltage": rollup.peakVoltage + " V"

                        })
                }

                this.transactionIds = [...new Set(this.sessionRollups.map(rollup => rollup["transaction id"]))];
            }
        },

        formatDurationToMinAndSec(duration) {
            const minutes = Math.floor(duration / 60);
            const seconds = Math.floor(duration % 60);

            if (minutes === 0) {
                return `${seconds}s`;
            } else if (seconds === 0) {
                return `${minutes} min`;
            } else {
                return `${minutes} min ${seconds} s`;
            }
        },

        renderColumn(value, key, item) {
            
            if(key == "componentName") {
                switch(value) {
                    case "DRIVERS_API":
                        return 'Drivers API';
                    case "OCPP_API":
                        return 'OCPP API';
                    case "CHARGEPOINT":
                        return 'Charge Point';
                    case "CENTRAL_SERVER":
                        return 'Central Server';
                    case "KAFKA_HANDLER":
                        return 'Kafka Handler';
                    case "PUSH_NOTIFICATION":
                        return 'Push Notification';
                    case "JOB_SCHEDULER":
                        return 'Job Scheduler';
                    case "ABLY":
                        return 'Ably';
                    default:
                        return value;
                }
            }

            if(key == "timestamp") {
                return this.formatTimestampWithMilliseconds(item["timestamp"])
            }
        },
        getBodyData(body) {
            let jsonBody = undefined
            //console.log(body)
            if(!body) {
                return ""
            }
            
            try {
                jsonBody = this.cleanJsonString(body)
                jsonBody = JSON.parse(jsonBody)
                jsonBody = this.parseJsonAttributes(jsonBody)
                return JSON.stringify(jsonBody,null, 2)
            } catch (error) {
                jsonBody = body.replace(/,\s*/g, ",\n");
                return jsonBody
            }  

        },
        getNotificationData: function(body) {
            if(!body) {
                return {}
            }

            console.log(body)
            let payload = undefined
            try {
                payload = typeof(body) === 'string'
                ? JSON.parse(body)
                : body
            } catch {
                return body
            }

            if(payload) {
                return this.isJsonObject(payload)
                    ? JSON.parse(payload)
                    : payload
            } else {
                return body
            }
        },
        isJsonObject: function(obj) {
            if(!obj) {
                return false
            }
            
            return typeof(obj) === 'string'
                && (obj.startsWith("[") || obj.startsWith("{"))
                && (obj.startsWith("]") || obj.startsWith("}"))
        },
        getSuccessBadgeVariant(success) {
            return success ? 'success' : 'danger'
        },
        getComponentClassVariant(component) {
            switch(component) {
                case "DRIVERS_API":
                    return 'badge-drivers-api';
                case "OCPP_API":
                    return 'badge-ocpp-api';
                case "CHARGEPOINT":
                    return 'badge-chargepoint';
                case "CENTRAL_SERVER":
                    return 'badge-central-server';
                case "KAFKA_HANDLER":
                    return 'badge-kafka-handler';
                case "PUSH_NOTIFICATION":
                    return 'badge-push-notification';
                case "JOB_SCHEDULER":
                    return 'badge-job-scheduler';
                case "ABLY":
                    return 'badge-ably';
                default:
                    return 'secondary';
            }
        },
        cleanJsonString(jsonStr) {
            // Remove leading and trailing whitespaces
            jsonStr = jsonStr.trim();

            // Remove extra spaces around keys and values
            jsonStr = jsonStr.replace(/\s*:\s*/g, ':');
            jsonStr = jsonStr.replace(/\s*,\s*/g, ',');
            jsonStr = jsonStr.replace(/\s*{\s*/g, '{');
            jsonStr = jsonStr.replace(/\s*}\s*/g, '}');
            jsonStr = jsonStr.replace(/\s*\[\s*/g, '[');
            jsonStr = jsonStr.replace(/\s*\]\s*/g, ']');

            return jsonStr;
        },
        parseJsonAttributes(obj) {
            let parsedData = {};

            // Iterate over each key in the object
            Object.keys(obj).forEach(key => {
                let value = obj[key];

                // Check if the value is a string and can be parsed as JSON
                if (typeof value === 'string') {
                try {
                    let parsedValue = JSON.parse(value);
                    parsedData[key] = parsedValue; // Assign parsed value to the key
                } catch (error) {
                    parsedData[key] = value; // If parsing fails, assign original value
                }
                } else {
                parsedData[key] = value; // Assign non-string values directly
                }
            });

            return parsedData;
        },
        limitCharactersPerLine(str, maxCharsPerLine) {
            let result = '';
            let line = '';
            
            for (let i = 0; i < str.length; i++) {
                line += str[i];
                if (line.length >= maxCharsPerLine && str[i] === ' ') {
                result += line.trim() + '\n';
                line = '';
                }
            }
            
            // Append any remaining text
            if (line.length > 0) {
                result += line.trim();
            }

            return result;
        },
        onFilterChange() {
            if (!this.selectedComponents.length) {
                this.filteredLogMessages = this.logMessages
                return
            }

            const allLogMessages = this.logMessages

            this.filteredLogMessages = allLogMessages.filter(message => {
                if(this.selectedComponents.includes(message.componentName)) {
                    return true
                } else {
                    return false
                }
            });

            console.log(this.selectedComponents)
            console.log(this.filteredLogMessages)

            this.totalRows = this.filteredLogMessages.length
            this.currentPage = 1
        },
        prepareReservationLogFlow() {
            const distinctComponentNames = new Set()
            for (const logMessage of this.logMessages) {
                distinctComponentNames.add(logMessage.componentName)
            }

            this.components = Array.from(distinctComponentNames)
            this.components = this.components.map(component => {
                return { name: component, value: component }
            })
        },
        getLocalDateTime: function(time) {
            const endTime = new Date(time);

            const options = {
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: false
                };

                const localeTime = endTime.toLocaleTimeString('en-US', options);
                const milliseconds = endTime.getMilliseconds().toString().padStart(3, '0');
                const formattedDate = `${localeTime}.${milliseconds}`;

                return formattedDate
            }
        }, 
    async mounted() {
        this.isLoading = true
        await this.getReservationById()
        this.prepareReservationLogFlow()
        this.totalRows = this.filteredLogMessages.length
        this.isLoading = false
    }
}
</script>

<template>
    <div class="reservation-details page">
        <div v-if="isLoading" class="loading-indicator">
            <p>Loading data, please wait...</p>
        </div>
        <div class="reservation-details-page-container" v-else>
            <div v-if="!reservationDetails" class="no-data-found">
                <img src="../../assets/actions/no_data.svg">
                <p>No <strong>reservation details</strong> found</p>
            </div>
            <div v-else>
                <div class="row page-header-container">
                    <div class="page-header">
                        <h1>Reservation details for reservation #{{ this.$route.params.id }}</h1>
                    </div>
                </div>

                <div class="row tabs-container">
                    <b-tabs>
                        <b-tab :active="$route.hash === '#reservation-details'">
                            <template v-slot:title>
                                <router-link to="#reservation-details">
                                    <span class="tab-title">
                                        <strong>Reservation Details</strong>
                                    </span>
                                </router-link>
                            </template>
                            <div v-if="!reservationDetails" class="no-data-found">
                                <img src="../../assets/actions/no_data.svg">
                                <p>No <strong>reservation details</strong> found</p>
                            </div>
                            <div v-else class="container-fluid">
                                <div class="row">
                                    <div class="col-md-6 col-sm-12">
                                        <div class="card info">
                                            <div class="card-header">
                                                <h3>General Info</h3>
                                            </div>
                                            <div class="card-body">
                                                <ul class="card-list">
                                                    <li>
                                                        <span class="title">Reservation ID</span>
                                                        <span class="value">{{ this.reservation.id }}</span>
                                                    </li>
                                                    <li>
                                                        <span class="title">Start Time</span>
                                                        <span class="value">{{ new
                                                        Date(this.reservation.startTime).toLocaleString('en-US', {
                                                            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
                                                        }) }}</span>
                                                    </li>
                                                    <li>
                                                        <span class="title">End Time</span>
                                                        <span class="value">{{ new
                                                        Date(this.reservation.endTime).toLocaleString('en-US', {
                                                            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
                                                        }) }}</span>
                                                    </li>
                                                    <li>
                                                        <span class="title">Duration</span>
                                                        <span class="value">{{ this.reservation.duration }} min</span>
                                                    </li>
                                                    <li>
                                                        <span class="title">End Reason</span>
                                                        <span class="value">{{ this.reservation.endReason ? this.reservation.endReason : "Unknown / Active" }} </span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-sm-12">
                                        <div class="card info">
                                            <div class="card-header">
                                                <h3>Details</h3>
                                            </div>
                                            <div class="card-body">
                                                <ul class="card-list">
                                                    <li>
                                                        <span class="title">Station Name</span>
                                                        <span class="value">{{ this.reservation.stationName }}</span>
                                                    </li>
                                                    <li>
                                                        <span class="title">Port #</span>
                                                        <span class="value">{{ this.reservation.portName }}</span>
                                                    </li>
                                                    <li>
                                                        <span class="title">Driver ID</span>
                                                        <span class="value">{{ this.reservation.driverId }}</span>
                                                    </li>
                                                    <li>
                                                        <span class="title">Vehicle</span>
                                                        <span class="value">{{ this.reservation.evMake }} {{
                                                        this.reservation.evModel }}, {{
                                                        this.reservation.evYear }} 
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span class="title">Total Energy</span>
                                                        <span class="value">{{ this.reservation.totalEnergy }}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-4 col-sm-12">
                                        <div class="card info">
                                            <div class="card-header">
                                                <h3>Reservation Overview</h3>
                                            </div>
                                            <div class="card-body">
                                                <ul class="card-list">
                                                    <li>
                                                        <span class="title">Session Duration</span>
                                                        <span class="value">{{ this.reservation.sessionDuration
                                                            }}</span>
                                                    </li>
                                                    <li>
                                                        <span class="title">Charge Duration</span>
                                                        <span class="value">{{ this.reservation.chargeDuration }}</span>
                                                    </li>
                                                    <li>
                                                        <span class="title">EV Mileage</span>
                                                        <span class="value">{{ this.reservation.milesToCharge }}
                                                            miles</span>
                                                    </li>
                                                    <li>
                                                        <span class="title">EV Range Added</span>
                                                        <span class="value">{{ this.reservation.milesCharged }}
                                                            miles</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4 col-sm-12">
                                        <div class="card info">
                                            <div class="card-header">
                                                <h3>Reservation Meter Values</h3>
                                            </div>
                                            <div class="card-body">
                                                <ul class="card-list">
                                                    <li>
                                                        <span class="title">Avg Charging Speed</span>
                                                        <span class="value">{{ this.reservation.avgChargingSpeed }}
                                                            mph</span>
                                                    </li>
                                                    <li>
                                                        <span class="title">Avg Power</span>
                                                        <span class="value">{{ this.reservation.avgPower }} kW</span>
                                                    </li>
                                                    <li>
                                                        <span class="title">Total Energy</span>
                                                        <span class="value">{{ this.reservation.totalEnergy }}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4 col-sm-12">
                                        <div class="card info">
                                            <div class="card-header">
                                                <h3>OCPP Transaction IDs</h3>
                                            </div>
                                            <div class="card-body">
                                                <ul v-for="(transactionId, index) in transactionIds" :key="transactionId" class="card-list">
                                                    <li>
                                                        <span class="title">#{{ index + 1 }}</span>
                                                        <span class="value">{{ transactionId }}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-12 col-sm-12">
                                        <div class="card info">
                                            <div class="card-header">
                                                <h3>Session rollups</h3>
                                            </div>
                                            <div class="card-body">
                                                <div>
                                                    <b-table striped hover :items="sessionRollups"></b-table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </b-tab>
                        <b-tab :active="$route.hash === '#user-data'">
                            <template v-slot:title>
                                <router-link to="#user-data">
                                    <span class="tab-title">
                                        <strong>User Data</strong>
                                    </span>
                                </router-link>
                            </template>
                            <div v-if="!reservation" class="no-data-found">
                                <img src="../../assets/actions/no_data.svg">
                                <p>No <strong>user data</strong> found</p>
                            </div>
                            <div v-else class="container-fluid">
                                <div class="row">
                                    <div class="col-md-6 col-sm-12">
                                        <div class="card info">
                                            <div class="card-header">
                                                <h3>Personal Data</h3>
                                            </div>
                                            <div class="card-body">
                                                <ul class="card-list">
                                                    <li>
                                                        <span class="title">User ID</span>
                                                        <span class="value">{{ this.driver.driverId }}</span>
                                                    </li>
                                                    <li>
                                                        <span class="title">Total # of reservations</span>
                                                        <span class="value">{{ this.driver.totalReservations }}</span>
                                                    </li>
                                                    <li>
                                                        <span class="title">Lifetime conduct</span>
                                                        <span class="value">{{ this.driver.lifetimeConduct }}</span>
                                                    </li>
                                                    <li>
                                                        <span class="title">30 day conduct</span>
                                                        <span class="value">{{ this.driver.monthlyConduct }}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div class="card info">
                                            <div class="card-header">
                                                <h3>User Vehicles</h3>
                                            </div>
                                            <div class="card-body">
                                                <div>
                                                    <b-table striped hover :items="driverVehicles"></b-table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-sm-12">
                                        <div class="card info">
                                            <div class="card-header">
                                                <h3>Charging Data</h3>
                                            </div>
                                            <div class="card-body">
                                                <ul class="card-list">
                                                    <li>
                                                        <span class="title">Average charging time</span>
                                                        <span class="value">{{ formatDurationToMinAndSec(this.driver.averageChargeDuration)
                                                            }}</span>
                                                    </li>
                                                    <li>
                                                        <span class="title">Average charging time standard
                                                            deviation</span>
                                                        <span class="value">{{ formatDurationToMinAndSec(this.driver.stddevChargeDuration) }}
                                                            </span>
                                                    </li>
                                                    <li>
                                                        <span class="title">Average reservation time</span>
                                                        <span class="value">{{ formatDurationToMinAndSec(this.driver.averageReservationDuration*60) }}
                                                            </span>
                                                    </li>
                                                    <li>
                                                        <span class="title">Average reservation time standard
                                                            deviation</span>
                                                        <span class="value">{{ formatDurationToMinAndSec(this.driver.stddevReservationDuration*60) }}
                                                            </span>
                                                    </li>
                                                    <li>
                                                        <span class="title">Average session duration</span>
                                                        <span class="value">{{ formatDurationToMinAndSec(this.driver.averageSessionDuration) }}
                                                            </span>
                                                    </li>
                                                    <li>
                                                        <span class="title">Average session duration standard
                                                            deviation</span>
                                                        <span class="value">{{ formatDurationToMinAndSec(this.driver.stddevSessionDuration) }}
                                                            </span>
                                                    </li>
                                                    <li>
                                                        <span class="title">Total reservations</span>
                                                        <span class="value">{{ this.driver.totalReservations }}</span>
                                                    </li>
                                                    <li>
                                                        <span class="title">Total energy</span>
                                                        <span class="value">{{ this.driver.totalEnergy }} kWh</span>
                                                    </li>
                                                    <li>
                                                        <span class="title">Total requested miles</span>
                                                        <span class="value">{{ this.driver.totalRequestedMiles }} miles</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </b-tab>

                        <b-tab :active="$route.hash === '#reservation-flow'">
                            <template v-slot:title>
                                <router-link to="#reservation-flow">
                                    <span class="tab-title">
                                        <strong>Reservation Flow</strong>
                                    </span>
                                </router-link>
                            </template>
                            <div v-if="!reservation" class="no-data-found">
                                <img src="../../assets/actions/no_data.svg">
                                <p>No <strong>reservation flow data</strong> found</p>
                            </div>
                            <div v-else class="container-fluid mt-5">
                                <div class="row">
                                    <div class="form-field col-md-6 col-sm-12">
                                        <label for="components">Search</label>
                                        <b-input-group size="md" style="border-radius: 0 var(--ms-tag-mx,4px) var(--ms-tag-my,4px);">
                                            <b-form-input id="filter-input" v-model="filter" type="search"
                                                placeholder="Type to search..."></b-form-input>
                                        </b-input-group>
                                    </div>
                                    <div class="form-field col-md-6 col-sm-12" style="z-index: 999;" >
                                        <label for="components">Components</label>
                                        <Multiselect v-model="selectedComponents" :options="components" mode="tags"
                                            :close-on-select="false" placeholder="Select components(s)" label="name" track-by="name"
                                            @select="onFilterChange()" @deselect="onFilterChange()" :show-labels="false">
                                        </Multiselect>
                                    </div>
                                    
                                </div>
                                <div class="row mt-3">
                                    <div class="form-field col-md-12 col-sm-12">
                                        <b-table :items="filteredLogMessages" :fields="fields" :current-page="currentPage"
                            :per-page="perPage" :filter="filter" stacked="md" show-empty small hover striped responsive select-mode="single">

                                            <template #head()="data">
                                                <div class="text-nowrap" style="color: #fff">
                                                {{ data.label }}
                                                </div>
                                            </template>

                                            <template #cell(systemInitiated)="data">
                                                <b-badge class="pill">{{ data.item.systemInitiated ? 'Yes' : 'No' }}</b-badge>
                                            </template>

                                            <template #cell(success)="data">
                                                <b-badge :variant="getSuccessBadgeVariant(data.item.success)">{{ data.item.success ? 'Success' : 'Error' }}</b-badge>
                                            </template>

                                            <template #cell(componentName)="data">
                                                <b-badge :class="getComponentClassVariant(data.item.componentName)">{{ data.value }}</b-badge>
                                            </template>

                                            <!-- <template #cell(body)="data">
                                                <vue-json-pretty
                                                    :data="data.value">
                                                </vue-json-pretty>
                                            </template> -->

                                            <template #cell(timestamp)="data">
                                                {{ getLocalDateTime(data.item.timestamp) }}
                                            </template>

                                            <template #cell(body)="data">
                                                <pre>{{ data.value }}</pre>
                                            </template>

                                            <template #cell(action)="data">
                                                <b-button size="md" @click="data.toggleDetails">
                                                    {{ data.detailsShowing ? 'Hide' : 'Show' }} Details
                                                </b-button>

                                            </template>

                                            <template #row-details="data">
                                                <div class="card-body">
                                                    <div class="code-preview">
                                                        <vue-json-pretty
                                                            :data="getNotificationData(data.item.body)">
                                                        </vue-json-pretty>
                                                    </div>
                                                </div>

                                                <!-- <vue-json-pretty
                                                    :data="getBodyData(data.item.body)">
                                                </vue-json-pretty> -->
                                                <!-- <b-card>
                                                    <pre>{{ getBodyData(data.item.body) }}</pre>
                                                </b-card> -->
                                            </template>
                                        </b-table>
                                    </div>
                                    <div class="row">
                                        <div class="form-field col-md-3 col-sm-12"></div>
                                        <div class="form-field col-md-6 col-sm-12">
                                            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill"
                                                size="sm" class="my-0"></b-pagination>
                                        </div>
                                        <div class="form-field col-md-3 col-sm-12"></div>
                                    </div>
                                </div>
                            </div>
                        </b-tab>


                    </b-tabs>
                </div>

            </div>
        </div>
    </div>
</template>


<style src="@vueform/multiselect/themes/default.css"></style>
<style lang="scss" scoped>

.page.reservation-details {
    overflow: auto;

    .row.page-header-container {
        z-index: 500;
    }
}

.reservation-details-page-container {
    height: 100%;

    .container-fluid {
        height: 100%;
        overflow: auto;
    }
}

.reservation-details.page {
    padding: 0px !important;
    width: 100%;
}

.tabs-container {
    padding: 0px !important;
    min-height: 100%;
    overflow: hidden;
}

.tabs {
    width: 100%;
    min-height: 100%;
    overflow: auto;

    .tab-content {
        overflow: auto;
        -ms-overflow-style: none;
        /* Internet Explorer 10+ */
        scrollbar-width: none;
        /* Firefox */
        padding-bottom: 80px !important;

        &::-webkit-scrollbar {
            display: none;
            /* Safari and Chrome */
        }

        &>.tab-pane {
            height: 100%;
        }
    }

    .nav-tabs {
        padding: 0px 20px;
        background-color: #1d395d !important;
        color: #fff !important;
        z-index: 1000;
        box-shadow: 12px 0px 4px rgba(0, 0, 0, 0.4);
        border-bottom: 0px !important;

        .nav-item {
            background-color: transparent !important;

            a {
                text-decoration: none !important;
            }

            .nav-link {
                background-color: transparent !important;
                border: 0px !important;
                padding: 6px 8px;
                transition: all 0.3s ease-in-out !important;

                &.active {
                    .tab-title {
                        strong {
                            border-bottom: 4px solid rgb(255, 255, 255) !important;
                            color: rgb(255, 255, 255) !important;
                            opacity: 1;
                        }
                    }
                }

                .tab-title {
                    padding: 4px;
                    padding-top: 8px !important;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #fff !important;
                    transition: all 0.3s ease-in-out !important;
                    border-bottom: 4px solid rgb(255, 255, 255, 0) !important;
                    margin: 0px 12px !important;

                    strong {
                        font-size: 14px;
                        font-weight: 800;
                        text-transform: uppercase;
                        padding: 4px 0px;
                        opacity: 0.6;
                    }
                }
            }

            &:first-child {
                .tab-title {
                    margin: 0px !important
                }
            }
        }
    }
}

.tab-pane.active {
    animation: slide-left 0.5s ease-in-out;
}

.multiselect--active {
    z-index: 999;
}

.badge-drivers-api {
    background-color: #264653 !important;
    /* Custom green color */
    color: white !important;
}

.badge-ocpp-api {
    background-color: #2a9d8f !important;
    /* Custom red color */
    color: white !important;
}

.badge-chargepoint {
    background-color: #e9c46a !important;
    /* Custom yellow color */
    color: black !important;
}

.badge-paused {
    background-color: #f4a261 !important;
    /* Custom gray color */
    color: white !important;
}

.badge-finished {
    background-color: #e76f51 !important;
    /* Custom gray color */
    color: white !important;
}

.badge-central-server {
    background-color: #606c38 !important;
    /* Custom yellow color */
    color: white !important;
}

.badge-kafka-handler {
    background-color: #283618 !important;
    /* Custom gray color */
    color: white !important;
}

.badge-push-notification {
    background-color: #dda15e !important;
    /* Custom gray color */
    color: black !important;
}

.badge-job-scheduler {
    background-color: #bc6c25 !important;
    /* Custom gray color */
    color: white !important;
}

.badge-ably {
    background-color: #f4a261 !important;
    /* Custom gray color */
    color: black !important;
}

button, button:active {
    color: #000;
    padding-left: 3px !important;
    padding-right: 3px !important;
}

.code-preview {
    text-align: left !important;
    line-height: 1.6 !important;
}

</style>